import { useTranslation } from "react-i18next";

import { Typography, Link } from "@aviary";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { e2eAttributes } from "@unauthenticated/shared/e2eAttributes";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./NewToFullscriptSignUp.styles";

interface Props {
  userType: "practitioner" | "patient";
  customSignUpAction?: () => void;
}

const NewToFullscriptSignUp = ({ userType, customSignUpAction }: Props) => {
  const { t } = useTranslation();
  const { isEmerson } = useSharedGlobalConfig();
  const [isEEV2SignupEnabled] = useFlippers("lighthouse_signup_2024q1_eev2_signup_release");

  const renderLink = () => {
    if (customSignUpAction) {
      return (
        <Link
          css={styles.link}
          data-e2e="link-to-custom-signup-action"
          isUnderLined={false}
          isColor="success"
          onClick={customSignUpAction}
        >
          {t(l.signIn.CreateAccount)}
        </Link>
      );
    }

    const emersonClassicRoute = isProduction()
      ? "https://account.emersonecologics.com/register/emerson/register"
      : "https://account.stage.emersonecologics.com/register/emerson/register";

    const renderRoute = () => {
      if (isEmerson) return isEEV2SignupEnabled ? authRoutes.emerson.sign_up : emersonClassicRoute;
      if (userType === "practitioner") return authRoutes.practitioner_sign_up;
      if (userType === "patient") return authRoutes.patient_sign_up;
    };

    return (
      <Link
        to={renderRoute()}
        css={styles.link}
        data-e2e={userType === "practitioner" && e2eAttributes.login.createPractitionerLink}
        isUnderLined={false}
        isColor="success"
      >
        {t(l.signIn.CreateAccount)}
      </Link>
    );
  };

  return (
    <Typography isFullWidth>
      {t(isEmerson ? l.signIn.Emerson.NewToEmersonEcologics : l.signIn.NewToFullScript)} {""}
      {renderLink()}
    </Typography>
  );
};

export { NewToFullscriptSignUp };
