import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Spacer, Typography } from "@aviary";
import { AppleSSOButton, GoogleSSOButton, MagicLinkButton } from "@shared/components";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useHydrated } from "@shared/hooks/useHydrated/useHydrated";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { useLocation } from "@shared/react-router-dom";
import type { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";
import { AccountLockedMessage } from "@unauthenticated/shared/components/AccountLockedMessage";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/shared/locales/i18n";

import { ErrorBox } from "../ErrorBox";
import { FormWrapper } from "../FormWrapper";
import { NewToFullscriptSignUp } from "../NewToFullscriptSignUp";
import { SSOErrorBox } from "../SSOErrorBox/SSOErrorBox";
import type { SsoErrorPage } from "../SSOErrorBox/types";
import { SignInForm } from "../SignInForm";
import { SignInWrapper } from "../SignInWrapper";
import { UnauthorizedErrorBox } from "../UnauthorizedErrorBox";

import * as styles from "./SignInBlock.styles";

interface TwoFactorData {
  otpMethod: TwoFactorAuthenticationMethods;
  userEmail: string;
  authToken: string;
}

interface Props {
  additionalAttributes?: any;
  ssoErrorPage?: SsoErrorPage;
  ssoErrors?: string;
  authErrors?: string;
  onCompleted?: (data) => void;
  onSignUpRedirect?: () => void;
  isOAuth?: boolean;
  onShowTwoFactorFlow?: (data: TwoFactorData) => void;
  isLockedOut?: boolean;
}

const SignInBlock = ({
  additionalAttributes,
  ssoErrorPage,
  ssoErrors,
  authErrors,
  onCompleted,
  onSignUpRedirect,
  isOAuth,
  onShowTwoFactorFlow,
  isLockedOut,
}: Props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { tablet, phoneLarge } = useBreakpoints();
  const [hasInputErrors, setHasInputErrors] = useState(false);
  const { isEmerson } = useSharedGlobalConfig();
  const hydrated = useHydrated();

  const renderError = () => {
    const unauthorized = new URLSearchParams(search).get("error");
    if (unauthorized) return <UnauthorizedErrorBox />;

    if (ssoErrors) {
      return (
        <SSOErrorBox
          ssoErrorPage={ssoErrorPage}
          ssoErrors={ssoErrors}
          onRedirect={onSignUpRedirect}
        />
      );
    }

    if (authErrors && !hasInputErrors) return <ErrorBox error={authErrors} />;

    if (isLockedOut) {
      return (
        <div css={styles.accountLockedWrapper}>
          <AccountLockedMessage />
        </div>
      );
    }

    return null;
  };

  return (
    <FormWrapper>
      <div css={styles.fullWidth}>
        <Typography
          css={styles.textCenter}
          isFullWidth
          type="h2"
          sizeOverride={tablet.lessThan ? "h3" : null}
        >
          {t(l.signIn.signInAccount)}
        </Typography>
        {phoneLarge.greaterThan && <Spacer height="one" />}
        {renderError()}
        {!isEmerson && hydrated && (
          <>
            <GoogleSSOButton additionalAttributes={additionalAttributes} />
            <AppleSSOButton
              css={styles.continueWithButton}
              additionalAttributes={additionalAttributes}
            />
            <MagicLinkButton buttonStyles={styles.continueWithButton} isOrTextRendered />
          </>
        )}
        <SignInWrapper>
          <SignInForm
            additionalAttributes={additionalAttributes}
            setHasInputErrors={setHasInputErrors}
            forgotPasswordLink={
              isEmerson ? authRoutes.emerson.forgot_password : authRoutes.forgot_password
            }
            onCompleted={onCompleted}
            isOAuth={isOAuth}
            onShowTwoFactorFlow={onShowTwoFactorFlow}
          />
          <Spacer height="doubleHalf" />
          <NewToFullscriptSignUp userType="practitioner" customSignUpAction={onSignUpRedirect} />
        </SignInWrapper>
      </div>
    </FormWrapper>
  );
};

export { SignInBlock };
