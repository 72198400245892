import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Pill } from "@aviary/components/Pill";
import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { l } from "@aviary/locales/i18n";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import type { AvatarProps } from "./Avatar.types";
import { DeprecatedAvatar } from "./DeprecatedAvatar/DeprecatedAvatar";
import { PlatformBadge } from "./PlatformBadge";

import * as styles from "./Avatar.styles";

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/display/Avatar
 */
const Avatar = ({
  size = "medium",
  isWellevate = false,
  overlayPosition = "bottomLeft",
  ...props
}: AvatarProps) => {
  const { t } = useTranslation();
  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled)
    return <DeprecatedAvatar {...{ size, isWellevate, overlayPosition, ...props }} />;

  const {
    image,
    firstName,
    lastName,
    label,
    // isLight only is used for DS3 version, so we're extracting it out
    // here to ensure no DOM errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLight,
    userStatus,
    children,
    ...rest
  } = props;

  const wrapperStyles = [
    styles.avatar,
    size && styles.sizes[size],
    userStatus && !image && styles.avatarWithStatus,
  ];

  const overlayChipStyles = [
    styles.overlayChip,
    overlayPosition && styles.overlayPosition[overlayPosition](size, isWellevate),
  ];

  const imageStyles = [styles.image, userStatus && styles.avatarWithStatus];

  const canRenderName = firstName && lastName && firstName.length > 0 && lastName.length > 0;

  const getNonAvatarContents = () => {
    if (canRenderName) {
      const initials = firstName[0] + lastName[0];

      return initials.toUpperCase();
    }

    return <FontAwesomeIcon icon={faUser} />;
  };

  const renderStatusPill = () => {
    if (!userStatus) return null;

    if (userStatus === "archived") {
      return (
        <div css={styles.userStatusPosition}>
          <Pill isColor="warning" size="xsmall">
            {t(l.aviary.avatar.archived)}
          </Pill>
        </div>
      );
    }

    return (
      <div css={styles.userStatusPosition}>
        <Pill isColor="danger" size="xsmall">
          {t(l.aviary.avatar.blocked)}
        </Pill>
      </div>
    );
  };

  const renderOverlayChip = () => {
    if (isWellevate && !children) {
      return (
        <div css={overlayChipStyles}>
          <PlatformBadge size={size} />
        </div>
      );
    }

    return children && <div css={overlayChipStyles}>{children}</div>;
  };

  const renderImageOrName = () => {
    if (image) {
      return <img src={image} css={imageStyles} alt={label} aria-hidden={!label} />;
    }

    return <span>{getNonAvatarContents()}</span>;
  };

  return (
    <div css={wrapperStyles} {...rest}>
      {renderImageOrName()}
      {renderStatusPill()}
      {renderOverlayChip()}
    </div>
  );
};

export { Avatar };
